import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Layout from "../components/shared-layout";
import useStores from '../hooks/use-stores';
import { observer } from 'mobx-react';
import LoadingPanel from '../components/loading-panel';
import LockIcon from '@material-ui/icons/Lock';
import { CircularProgress, TextField } from '@material-ui/core';
import { useStyles } from '../constants/style';
import { useTranslation } from 'react-i18next';


const UserPage = observer(() => {
    const { userStore, rootStore } = useStores();
    const { t } = useTranslation();
    const classes = useStyles();
    const [showResetArea, setShowResetArea] = useState<boolean>(false);
    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [photo, setPhoto] = useState<string>(
        typeof window !== "undefined" && localStorage.USER_INFO ? JSON.parse(localStorage.USER_INFO).photo : ""
    );
    const resetPassword = () => {
        if (oldPassword == "" || newPassword == "" || confirmPassword == "") {
            rootStore.notify(t('PLEASE_FILL_IN_ALL_FIELDS'), 'warning');
            return;
        }
        if (newPassword != confirmPassword) {
            rootStore.notify(t('TWO_PASSWORDS_NOT_MATCH'), 'warning');
            return;
        }
        console.log({
            uid: typeof window !== "undefined" && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO).uid,
            oldPassword,
            newPassword
        });
        userStore.resetPassword({
            uid: typeof window !== "undefined" && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO).uid,
            oldPassword,
            newPassword
        }).then(() => {
            setShowResetArea(false);
            setNewPassword("");
            setOldPassword("");
            setConfirmPassword("");
            rootStore.notify(`${t('RESET_PASSWORD_SUCCESS')}!`, 'success');
        }).catch((err) => {
            rootStore.notify(`${t('ERROR')}:` + err, 'error');
        });
    }

    return (
        <>
            {
                typeof window !== "undefined" &&
                <Layout
                    showSeachStudent={false}
                    showFilter={false}
                    showNewsArea={true}
                    showYearSemTerm={false}
                    showClassCode={false}
                    showDomain={false}
                    showStaffList={false}
                >
                    <title>{t('USER_PROFILE')}</title>
                    <div className="col-span-4 flex flex-col">
                        <div className="mx-2 mt-2">
                            <div className="bg-barPrimary p-4 rounded-t-xl">
                                <div className="w-40 h-40" style={{ margin: "0 auto" }}>
                                    {photo != "" && <img
                                        className="w-full h-full cursor-pointer object-cover rounded-full border-8"
                                        src={photo}
                                        alt="logo"
                                    />}
                                </div>
                                <div className="w-full text-center text-2xl text-white mt-4">
                                    {t('MY_PROFILE')}
                                </div>
                            </div>

                            <div style={{ minHeight: '80vh' }}>
                                <div className="border rounded-b-xl shadow-lg mb-4">
                                    <div className="w-full text-center">
                                        <div className="w-full text-center text-3xl py-4 text-barPrimary">
                                            <span className="font-bold">
                                                {typeof window !== "undefined" && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO).title}.
                                            </span>
                                            {" "}
                                            {typeof window !== "undefined" && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO).firstName}
                                            {" "}
                                            {typeof window !== "undefined" && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO).lastName}
                                        </div>

                                        <div className="text-center pb-4 text-xl">
                                            {typeof window !== "undefined" && localStorage.USER_INFO && JSON.parse(localStorage.USER_INFO).email}
                                        </div>
                                    </div>


                                    <div className="w-full text-center my-4">
                                        <Button
                                            className="bg-buttonPrimary text-white p-2 px-4"
                                            component="span"
                                            onClick={() => setShowResetArea(true)}
                                        >
                                            <LockIcon /> <span className="ml-2">{t('RESET_PASSWORD')}</span>
                                        </Button>
                                    </div>
                                </div>

                                {
                                    showResetArea &&
                                    <div className="rounded-xl shadow-lg border">
                                        <div className="text-barPrimary text-2xl font-bold m-4 uppercase">{t('RESET_PASSWORD')}</div>
                                        <div className="p-20 py-4">
                                            <div className="w-1/2" style={{ margin: "0 auto" }}>
                                                <TextField
                                                    label={t('PREVIOUS_PASSWORD')}
                                                    type="password"
                                                    variant="outlined"
                                                    value={oldPassword}
                                                    className={`${classes.textField} w-full my-2`}
                                                    onChange={(value) => setOldPassword(value.target.value)}
                                                />
                                                <TextField
                                                    label={t('NEW_PASSWORD')}
                                                    type="password"
                                                    variant="outlined"
                                                    value={newPassword}
                                                    className={`${classes.textField} w-full my-2`}
                                                    onChange={(value) => setNewPassword(value.target.value)}
                                                />
                                                <TextField
                                                    label={t('CONFIRM_NEW_PASSWORD')}
                                                    type="password"
                                                    variant="outlined"
                                                    value={confirmPassword}
                                                    className={`${classes.textField} w-full my-2`}
                                                    onChange={(value) => setConfirmPassword(value.target.value)}
                                                />
                                            </div>
                                            <div className="w-full text-right mt-4">
                                                {
                                                    userStore.loading ? <CircularProgress className="text-barPrimary" />
                                                        :
                                                        <>
                                                            <Button
                                                                className="bg-buttonPrimary text-white p-2 mx-4"
                                                                component="span"
                                                                onClick={() => {
                                                                    setShowResetArea(false);
                                                                    setNewPassword("");
                                                                    setOldPassword("");
                                                                    setConfirmPassword("");
                                                                }}
                                                            >
                                                                {t('CANCEL')}
                                                            </Button>
                                                            <Button
                                                                className="bg-buttonPrimary text-white p-2"
                                                                component="span"
                                                                onClick={resetPassword}
                                                            >
                                                                {t('RESET_NOW')}
                                                            </Button>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </Layout>
            }
        </>
    )
})

export default UserPage;

